import React from 'react'
import './home.css'


const Home = () => {
  return (
    <header id="home">

        <div className="homeContent">
          <span className="introText"><span className="introName">Jean-Baptiste PHILIPPE </span> <br /> Développeur Web</span>
        </div>

       

    </header>
  )
}

export default Home